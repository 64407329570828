<template>
  <!-- 弹窗部分-->
  <el-dialog
    v-dialogDrag
    title="墨 点 白"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="75%"
  >
    //:close-on-click-modal=false 这个属性的作用是不让点击外面关闭弹窗
    <div style="color: darkorange;font-size: 64px;">
      墨 点 白
    </div>
  </el-dialog>
<!-- 弹窗部分-->
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      dialogVisible: true
    }
  }
}
</script>

